<template>
  <div>
    <div class="page-container">
      <div class="div-size">
        <div class="div-secundaria">
          <div class="page-col-1">
            <section class="page-header-new">
              <h2 class="font-weight-bold">Consultório Virtual</h2>
            </section>
            <div class="span-new"></div>
            <div class="config-content-new">
              <p class="p2 config-content-new--p1">
                A Unimed Grande Florianópolis tem um portfólio de produtos e
                serviços que podem facilitar o seu dia-a-dia dentro do seu
                consultório e promovendo melhorias contínuas na sua experiência
                para atendimento aos pacientes.
              </p>
              <p class="p2 config-content-new--p2">
                As ferramentas ofertadas por aqui, não possuem cobrança
                adicional e além de ajudar em seus atendimentos, permite que
                nossa Unimed possa gerar indicadores e melhorar seus processos
                internos, possibilitando inclusive a redução de custos.
              </p>
              <p class="p2 config-content-new--p3">
                Vale ressaltar que possuímos um programa de remuneração variável
                dentro da UGF, e um dos indicadores que ajudam a aumentar seus
                honorários é a utilização dos serviços digitais oferecidos, como
                por exemplo, utilizando o serviço do Hub de agendamento, você
                garante um acréscimo em seus honorários!
              </p>
            </div>
          </div>
          <div class="page-col-2">
            <section class="page-header-new">
              <h2 class="font-weight-bold">Serviços</h2>
            </section>
            <div class="span-new"></div>
            <div class="p2 links upperCase">
              <a
                @click="openTelemedicina()"
                class="links--internal"
                :class="this.$store.getters.isAdmin && 'disabled-admin'"
              >
                TELEMEDICINA
                <img
                  v-if="!this.loadingTeleMedicine"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loadingTeleMedicine"
                ></i>
              </a>
              <!-- <a @click="openPedidoMedico()" class="links--internal">
                PEDIDO MÉDICO
                <img
                  v-show="!this.loadingMedicalOrder"
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
                <i
                  class="fas fa-spinner fa-spin icon"
                  v-show="this.loadingMedicalOrder"
                ></i>
              </a> -->
              <a
                href="https://pep.pepunimed.com.br/Account/Login?ReturnUrl=%2f#"
                target="_blank"
                class="links--internal"
              >
                APS UNIMED
                <img
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </a>
              <a
                href="https://www.confirmationcall.com.br/confirmationcall-admin-lte/pages/v1/unimed.html"
                target="_blank"
                class="links--internal"
              >
                HUB DE AGENDAMENTO
                <img
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </a>
              <a
                href="https://resweb.appinterall.com.br/AutorizadorMedico/"
                target="_blank"
                class="links--internal"
              >
                RES - REGISTRO ELETRÔNICO DE SAÚDE
                <img
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </a>
              <a
                href="https://rda.unimedsc.com.br/cmagnet/Login.do"
                target="_blank"
                class="links--internal"
              >
                SGU - CARD
                <img
                  class="mdi-chevron-right"
                  src="@/assets/images/mdi-chevron-right.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <AcceptModalVue />
    </div>
  </div>
</template>

<script>
import {
  getLinkTelemedicina,
  getLinkPedidoMedico,
} from "../../../services/virtual-office/VirtualOfficeServices.js";
import AcceptModalVue from "../components/modal/AcceptModal.vue";
import { Firebase } from "../../../services/firebase/log-firebase";

export default {
  components: { AcceptModalVue },
  async mounted() {
    this.openLink = this.$route.query.openLink;

    if (this.openLink === "true") {
      this.openTelemedicina();
    }
    if(this.$store.getters.isApp) {    
      this.loadingTeleMedicine = true;  
      this.telemedicineLink = await getLinkTelemedicina(this.$store.getters.getUserName, false);
      this.loadingTeleMedicine = false;
    }
  },
  data() {
    return {
      loadingTeleMedicine: false,
      loadingMedicalOrder: false,
      telemedicineLink: null
    };
  },
  methods: {
    openPedidoMedico() {
      const crm = this.$store.getters.getUserName;
      this.getPedidoMedico(crm);
    },
    openTelemedicina() {
      if (!this.$store.getters.isAdmin) {
        const crm = this.$store.getters.getUserName;
        this.getTelemedicine(crm, false);
      }
    },
    async getTelemedicine(crm, valor) {
      try {    

        this.firebase.addInfoLog(
          `Obtendo link para o crm: ${crm}, valor: ${valor}`,
          "VIRTUAL_OFFICE"
        );

        if (this.$store.getters.isApp) {
          if (this.telemedicineLink) {
              this.openUrl(this.telemedicineLink);
            } else {
              this.showTermModal();
            }
        } else {
            this.loadingTeleMedicine = true;
            const response = await getLinkTelemedicina(crm, valor);
            
            this.firebase.addInfoLog(
              `Link para o crm: ${crm} obtido`,
              "VIRTUAL_OFFICE"
            );

            if (response) {
              this.firebase.addInfoLog(
                `Abrindo Link: ${response}  para o crm: ${crm}`,
                "VIRTUAL_OFFICE"
              );

              this.openUrl(response);
            } else {
              
              this.firebase.addInfoLog(
                `Exibindo termo para o crm: ${crm}`,
                "VIRTUAL_OFFICE"
              );

              this.showTermModal();
            }
            this.loadingTeleMedicine = false;
        }

        
      } catch (error) {
        this.firebase = new Firebase();
        console.error("getTelemedicine", error);
        this.firebase.addErrorLog(
              `Ocorreu um erro quando o CRM ${this.user.login} tentou acessar o consultorio virtual: ` + error,
              "VIRTUAL_OFFICE"
            );
        this.loadingTeleMedicine = false;
      }
    },
    async getPedidoMedico(crm) {
      try {
        this.loadingMedicalOrder = true;
        const response = await getLinkPedidoMedico(crm);
        this.openUrl(response);
        this.loadingMedicalOrder = false;
      } catch (error) {
        console.error("getPedidoMedico", error);
        this.loadingMedicalOrder = false;
      }
    },
    openUrl(url) {
      const aElement = document.createElement("a");

      if (this.$store.getters.isApp) {
        aElement.href = url.replace("https", "http");
      } else {
        aElement.href = url;
      }

      aElement.setAttribute("target", "_blank");
      aElement.click();
    },
    showTermModal() {
      this.$root.$emit("bv::show::modal", "accept-modal", "#btnShow");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  &-size {
    color: var(--green-0);
    height: 3rem;
    width: 2.2rem;
  }
}

.page-container {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
}

.div-secundaria {
  display: flex !important;
  justify-content: center !important;
  width: 117rem;
  max-width: 117rem;

  @media screen and (max-width: 1056px) {
    flex-direction: column;
  }
}

.div-size {
  display: flex !important;
  justify-content: center !important;
  width: 90%;
}

.page-col-1 {
  width: 100%;
  padding-right: 8%;

  @media screen and (max-width: 1056px) {
    padding-right: 0%;
  }
}

.page-col-2 {
  width: 42rem;
  min-width: 42rem;
  margin-top: 0px;

  @media screen and (max-width: 1056px) {
    width: 100%;
    min-width: 0rem;
    margin-top: 4rem;
  }
}

.page-header-new {
  text-align: left;
  margin-bottom: 1.5rem;
}

.span-new {
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--secondary-green);
}

.config-content-new {
  width: 100%;
  &--p1 {
    margin-top: 3.3rem;
  }
  &--p2 {
    margin-top: 1rem;
  }
  &--p3 {
    margin-top: 1rem;
  }
  &--p4 {
    margin-top: 2rem;
  }
}

.links {
  margin-top: 3.3rem;
  width: 100%;

  &--internal {
    display: flex;
    align-items: center;
    padding: 0 1rem 0 2rem;
    cursor: pointer;
    border-radius: 1rem;

    background-color: white;
    color: var(--primary);
    width: 100%;
    text-align: left;
    padding-left: 1.4rem;
    height: 5rem;
    margin-bottom: 2.5rem;

    img {
      margin-left: auto;
    }

    i {
      margin-left: auto;
    }
  }
}
a.links--internal {
  font-size: 14px;
}
.links--internal:hover {
  border: 0.1rem solid #0a5f55 !important;
  text-decoration: none;
}
.disabled-admin {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>
