import store from "../../store";
import { anchorBase64Download } from "../../utils/anchor-base64-download/anchor-base64-download";
import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";



export async function getCategories() {
  const resp = await axios.get(
    `${METALICA_URL}/categories`
  );
  return resp.data;
}

export async function getRequestHistory(
  category,
  page,
  size = 10) {
  const resp = await axios.get(
    `${METALICA_URL}/v2/service-types-history?${
      category ? "category=" + category + "&" : ""
    }page=${page}&size=${size}`
  );
  return resp.data;
}

export async function getIdsAttachment(protocol) {
  const resp = await axios.get(
    `${METALICA_URL}/attachement?numberProtocol=${protocol}`
  );
  return resp.data;
}

export async function downloadAttachment(id, protocol) {
  await axios
    .get(
      `${METALICA_URL}/attachement/download?numberProtocol=${protocol}&id=${id}`
    )
    .then((res) => {
      anchorBase64Download(
        store.getters.isApp,
        res.data.contentType,
        res.data.filename	? res.data.filename : 'file' ,
        res.data.file
      );
    });
}