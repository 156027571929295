const JMJOptions = [   
    {
        id:251,
        name: "Autorização",
        "sub-category": [
            {
                id: 571,
                name: "Consultar Reanálise da Guia"
            },
            {
                id:319,
                name: "Cancelamento de Autorização"
            },
            {
                id:330,
                name: "Consultar Status da Guia"
            },
            {
                id:570,
                name: "Solicitação de autorização"
            },
        ]
    },
    {
        id:260,
        name: "Benefícios",
        "sub-category": [
            {
                id: 355,
                name: "Gogood"
            },
            {
                id: 316,
                name: "Backbeat"
            },
             {
                id: 336,
                name: "Cursos E Treinamentos"
            },
             {
                id: 308,
                name: "Anuidade Crm"
            },
             {
                id: 321,
                name: "Certificado Digital"
            },
             {
                id: 302,
                name: "Agulhas Acupuntura"
            },
            {
                id:601,
                name: "Plano de saúde",
                manifestations: [
                    { 
                        id:670,
                        name: "2º via do boleto"
                    },
                    { 
                        id:1141,
                        name: "2º via carteirinha"
                    },
                    { 
                        id:944,
                        name: "Alterar data vencimento"
                    },
                    { 
                        id:945,
                        name: "Alterar forma de pagamento"
                    },
                    { 
                        id:946,
                        name: "Adesão e inclusão dependentes"
                    },
                    { 
                        id:947,
                        name: "Cancelamento e exclusão de dependentes"
                    },
                    { 
                        id:948,
                        name: "2º via contrato"
                    }
                ]
            },
            {
                id:572,
                name: "FEA"
            }
          
        ]
    },
    {
        id:259,
        name: "Desligamento"
    },
    {
        id:173,
        name: "Documentos",
        "sub-category": [
            {
                id: 367,
                name: "Informe de Rendimentos"
            },
            {
                id: 352,
                name: "Fundo de sustentabilidade"
            },
            {
                id: 562,
                name: "Declaração cancelamento de NF"
            },
            {
                id: 563,
                name: "Declaração Serviços Próprios"
            },
            {
                id: 423,
                name: "Outras Declarações",
                manifestations: [
                    { 
                        id:668,
                        name: "Carta de Carência"
                    },
                    { 
                        id:942,
                        name: "Demonstrativo de valores pagos"
                    },
                ]
            }
        ]
    },
    {
        id:265,
        name: "Financeiro",
        "sub-category": [
            {
                id: 307,
                name: "Analítica Financeira"
            },
            {
                id: 564,
                name: "Relatório de Atendimentos"
            },
            {
                id: 565,
                name: "Inadimplência"
            },
             {
                id: 202,
                name: "Negociações"
            },
             {
                id: 359,
                name: "Informação / Devolução De Cota Capital"
            },
             {
                id: 360,
                name: "Informação De Pagamento"
            },
             {
                id: 1141,
                name: "Reembolso"
            }
        ]
    },
    {
        id:262,
        name: "Treinamento",
         "sub-category": [
            {
                id: 405,
                name: "Sgu Card / Treinamento"
            },
            {
                id: 356,
                name: "Hub De Agendamento"
            },{
                id: 415,
                name: "Telemedicina"
            },{
                id: 385,
                name: "Portal Do Cooperado"
            },{
                id: 386,
                name: "Portal do Prestador"
            },{
                id: 567,
                name: "TASY"
            },{
                id: 568,
                name: "PACS"
            },{
                id: 569,
                name: "Pega Plantão"
            }
        ]
    }
];
export default JMJOptions;