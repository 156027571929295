<template>
  <div>
    <CustomModalTemplate
      modalId="request-history-modal"
      ref="request-history-modal"
      class="request-history-modal"
      noCloseOnBackdrop
    >
      <template slot="header">
        <div class="header-request-history color-green-2">
          Detalhes
        </div>
        <div @click="this.onCloseModalHandler" class="icon--close">
          <img src="../../../assets/icons/close-circle-outline-green.svg" />
        </div>
      </template>
      <template slot="content">
        <div class="content-principal">
          <div>
            <b-card class="custom-card my-4" border-variant="light">
              <div class="title-card-history">
                RETORNO DA UNIMED
              </div>

              <div class="request-text-block">
                <textarea rows="5" readonly v-model="$props.item.unimedReturn">
                </textarea>
              </div>
            </b-card>
          </div>
          <div>
            <b-card
              class="custom-card my-4"
              v-if="this.$props.attachments.length > 0"
              border-variant="light"
            >
              <div class="title-card-history">
                ANEXO(S)
              </div>

              <div style="display: flex;flex-wrap: wrap;">
                <div
                  v-for="file in this.$props.attachments"
                  :key="file.filename"
                  style="flex: 1 1 48%; "
                >
                  <a
                    download
                    class="text-primary upload-file-cursor p2"
                    @click="eventToDownloadFile(file)"
                  >
                    {{ limitString(file.filename, 35) }}
                  </a>
                </div>
              </div>
            </b-card>
          </div>
          <div>
            <b-card class="custom-card my-4" border-variant="light">
              <div class="title-card-history">
                DESCRIÇÃO DA SOLICITAÇÃO
              </div>

              <div class="request-text-block">
                <textarea rows="10" readonly v-model="$props.item.description">
                </textarea>                
              </div>
            </b-card>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="footer-div-container">
          <div class="footer-div-button">
            <b-button
              class="ml-4"
              variant="primary"
              @click="this.onCloseModalHandler"
            >
              Fechar
            </b-button>
          </div>
        </div>
      </template>
    </CustomModalTemplate>
  </div>
</template>

<script>
import CustomModalTemplate from "../../../components/custom-modal/CustomModalTemplate.vue";
import { downloadAttachment } from "../../../services/request-history/request-history";
export default {
  props: ["item", "attachments"],
  components: {
    CustomModalTemplate,
  },
  data() {
    return {
      buttonDisabled: true,
    };
  },
  methods: {
    limitString(str, limit) {
      if (str.length > limit) {
        return str.substring(0, limit - 3) + "...";
      }
      return str;
    },
    async eventToDownloadFile({ id, numberProtocol }) {
      try {
        this.loading = true;
        await downloadAttachment(id, numberProtocol);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("eventToDownloadFile", error);
      }
    },
    onCloseModalHandler() {
      this.$refs["request-history-modal"].$children[0].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: black;
}
.content-principal {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  display: flex;
  padding: 0rem 2rem 0rem;
}
.second--image-div {
  position: absolute;
  top: 0;
  right: 0;
  margin: 7px 2rem 0px 0px;
}
.icon--close {
  cursor: pointer;
  @extend .second--image-div;
  margin: 18px 2rem 0px 0px;
}

@media only screen and (max-width: 800px) {
  .content-principal {
    width: 100%;
  }
  .second--image {
    width: 60%;
    float: right;
  }
}
.footer-div-container {
  position: relative;
  width: 100%;
  padding: 0rem 0rem 0rem;
}
#request-history-modal .modal-footer {
  padding: 0rem 2.2rem;
}
.footer-div-button {
  position: relative;
  margin-top: 0rem;
  display: flex;
  justify-content: flex-end;
}
.line-footer {
  position: absolute;
  width: 100%;
  height: 8px;
  background: linear-gradient(to bottom, transparent 0%, #0000000a 80%);
}
.title-card-history {
  color: #7a7e83;
}
.custom-card {
  width: 100%;
}
.header-request-history {
  color: #23272b;
  font-size: 2rem;
}
.request-text-block {
  white-space: pre;
}
textarea {
  border: none;
  width: 100%;
}
</style>
