<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <div class="my-2 ml-auto">
          <b-select
            v-model="categorySelected"
            :options="categories"
            size="lg"
          />
        </div>
      </template>
      <template slot="titleLoading">
        <div class="align-refresh-history">
          <img
            v-show="loadingDataUpdate"
            v-if="loadingDataUpdate"
            class="img-reflesh-history img-reflesh-history--loadind"
            src="@/assets/images/refresh.svg"
          />
          <img
            v-show="!loadingDataUpdate"
            v-if="!loadingDataUpdate"
            class="img-reflesh-history"
            src="@/assets/images/refresh.svg"
          />
          <p v-text="onDataUpdate()" class="data-feedback-update"></p>
        </div>
      </template>
      <template slot="table">
        <b-table
          class="table-custom spaced-text"
          :fields="fields"
          :items="items"
          v-show="!this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template #cell(createdAt)="data">
            <div class="custom-row-table d-flex align-items-center">
              <p
                data-toggle="tooltip"
                data-placement="top"
                :title="dateToTime(data.item.createdAt)"
                class="p-custon"
              >
                {{ dateToLocaleDate(data.item.createdAt) }}
              </p>
            </div>
          </template>
          <template #cell(integrationJMJStatus)="data">
            <div
              class="custom-row-table d-flex justify-content-center align-items-center"
            >
              <img
                :src="getIconByStatus(data).src"
                data-toggle="tooltip"
                data-placement="top"
                :title="getIconByStatus(data).title"
              />
            </div>
          </template>
          <template #cell(details)="data">
            <div
              class="custom-row-table d-flex justify-content-center align-items-center"
            >
              <div
                @click="() => onClickViewRequest(data)"
                class="custom-row-table d-flex justify-content-center align-items-center"
              >
                <i class="text-primary table-icon-size fas fa-list"></i>
              </div>
            </div>
          </template>
        </b-table>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <b-pagination
          v-model="page"
          :total-rows="totalSize"
          :per-page="perPage"
          aria-controls="my-table"
          size="lg"
        />
      </template>
    </TableVisualizationLayout>
    <RequestHistoryModal
      :item="this.item"
      :attachments="this.selectedAttachments"
    />
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";
import {
  getRequestHistory,
  getIdsAttachment,
  getCategories,
} from "../../../services/request-history/request-history";
import RequestHistoryModal from "./RequestHistoryModal.vue";
import resolve_img_url from "../../../utils/image/image-utils";
import { IntegrationJMJStatus } from "../../../static-data/IntegrationJMJStatus.js";
import moment from "moment";
import { inject } from "@vue/composition-api";
import { createToast } from "../../../components/toast/toast"

export default {
  components: {
    RequestHistoryModal,
    TableVisualizationLayout,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  mounted() {
    const categorySelected = this.$route.params.category;
    this.getRequestCategories();
    this.getHistory(this.page, categorySelected);
  },
  watch: {
    page: {
      handler(newValue) {
        this.getHistory(newValue, this.categorySelected);
      },
    },
    categorySelected: {
      handler(newValue) {
        this.getHistory(this.page, newValue);
      },
    },
  },
  data() {
    return {
      loadingDataUpdate: false,
      dataUpdate: null,
      page: 1,
      perPage: 10,
      categorySelected: null,
      totalSize: 0,
      leftAction: {
        text: "Histórico de solicitações",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Todas as suas solicitações são analisadas e gerenciadas pela área de negócios. Você pode acompanhar o andamento e a conclusão de cada solicitação clicando no ícone ‘Detalhes’.",
      fields: [
        {
          key: "categoryName",
          label: "Categoria",
        },
        {
          key: "subcategoryName",
          label: "SubCategoria",
        },
        {
          key: "protocol",
          label: "Protocolo",
        },
        {
          key: "createdAt",
          label: "Data Abertura",
        },
        {
          key: "integrationJMJStatus",
          label: "Status",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "details",
          label: "Detalhes",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      integrationJMJStatus: IntegrationJMJStatus,
      selectedViewRequestItem: null,
      requestTypeDescription: "",
      selectedAttachments: [],
      categories: [],
      item: {},
      loading: true,
    };
  },
  methods: {
    async getRequestCategories() {
      const values = await getCategories();
      this.categories = [
        { value: null, text: "Todas as solicitações" },
        ...values.map(({ category, categoryName }) => ({
          value: category,
          text: categoryName,
        })),
      ];
    },
    getIconByStatus({ item }) {
      const staticStatus = this.integrationJMJStatus.find(
        (i) => i.enum == item.integrationJMJStatus
      );
      return {
        title: staticStatus.title(this.dateToLocaleDate(item.closedAt)),
        src: resolve_img_url(staticStatus.src),
      };
    },
    async getHistory(pageNumber, categorySelected) {
      try {
        this.loading = true;

        const { items, totalItems } = await getRequestHistory(
          categorySelected,
          pageNumber - 1,
          this.perPage
        );

        this.items = items;
        this.totalSize = totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    async onClickViewRequest(data) {
      this.globalLoading = true;
      this.item = data?.item;
      try {
        const attachments = await getIdsAttachment(data?.item?.protocol);
        this.selectedAttachments = attachments;
      }
      catch {        
        createToast("Informação", "Um crm deve ser informado.", "info");
      }
      this.globalLoading = false;      
      this.$bvModal.show("request-history-modal");
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onDataUpdate() {
      return `Última atualização em: ${this.onFormatDate(
        moment().format("DD/MM/YYYY HH:mm")
      )}`;
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>
<style lang="scss">
.table-icon-size {
  font-size: 1.8rem;
}
.align-refresh-history {
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: center;
}

.img-reflesh-history {
  height: 60%;
  padding-right: 0.2rem;
  &--loadind {
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.data-feedback-update {
  text-align: center;
}

.p-custon {
  font-size: 1.4rem;
  color: var(--grey-2);
}

.custom-row-table {
  height: 2.5rem;
  width: 100%;
}
</style>
